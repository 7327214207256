<template>
  <div>
      <ul :class="`flex gap-${tabNavGap} z-10 ml-4 ${hidden&&'hidden'}`">
        <li v-for="(tab, index) in tabLabels" :key="index" :class="`nav-item ${selectedTab === tab?'bg-white font-semibold shadow-small z-10 cursor-default':'bg-neutral-50 text-neutral-500 cursor-pointer'}   relative text-sm py-2 px-8 rounded-t-lg`" @click="setTab(tab)">
          {{ tab }}
          <div class="w-full h-4 absolute bg-white left-0 z-10" :class="{hidden : selectedTab !==tab}" ></div>
        </li>
      </ul>
    <div class="tabs-content relative shadow-small rounded-lg bg-white p-6 z-10">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data: function(){
    return {
    }
  },
  props: {
    tabLabels: {
      type: Array,
      required: true
    },
    selectedTab: {
      type: String,
      required: true
    },
    tabNavGap:{ // determine gap between each navigation tab
      type: Number,
      default: '1'
    },
    hidden:{
      type: Boolean,
      default: false
    }
  },
  methods:{
    setTab(tab){
      this.$emit('selected', tab)
    },
  }
}
</script>

<style lang="scss" scoped>
// .tabs {
//   ul {
//     list-style: none;
//     padding: 0;
//     margin: 0;
//     display: flex;
//     li {
//       padding: 10px 20px;
//       border: 1px solid #ccc;
//       border-bottom: none;
//       cursor: pointer;
//       &.active {
//         background: #ccc;
//       }
//     }
//   }
// }
</style></style>
