var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ul', {
    class: "flex gap-".concat(_vm.tabNavGap, " z-10 ml-4 ").concat(_vm.hidden && 'hidden')
  }, _vm._l(_vm.tabLabels, function (tab, index) {
    return _c('li', {
      key: index,
      class: "nav-item ".concat(_vm.selectedTab === tab ? 'bg-white font-semibold shadow-small z-10 cursor-default' : 'bg-neutral-50 text-neutral-500 cursor-pointer', "   relative text-sm py-2 px-8 rounded-t-lg"),
      on: {
        "click": function click($event) {
          return _vm.setTab(tab);
        }
      }
    }, [_vm._v(" " + _vm._s(tab) + " "), _c('div', {
      staticClass: "w-full h-4 absolute bg-white left-0 z-10",
      class: {
        hidden: _vm.selectedTab !== tab
      }
    })]);
  }), 0), _c('div', {
    staticClass: "tabs-content relative shadow-small rounded-lg bg-white p-6 z-10"
  }, [_vm._t("default")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }